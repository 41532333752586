<template>
  <skeleton-transation-component v-if="bSkeleton" />
  <!-- <p class="p-text-title">Transacciones</p> -->
  <div v-else class="content-transation">
    <div class="global-content-table">
      <searchbar-transation-component
        @setSearch="setSearch"
        @setRangeDate="setRangeDate"
        @setTransactionType="setTransactionType"
        @setRegistrationType="setRegistrationType"
        @setDialogAddTransation="setDialogAddTransation"
      />
      <no-data-text-component-global
        :iHeight="screenHeight"
        :sTextEmpty="sTextEmpty"
        v-if="bEmptyTable"
        :bAdminPermissions="bAdminAccount"
        setDialog="setDialogAddTransation"
        @setDialogAdd="setDialogAddTransation"
      />
      <v-data-table
        v-else
        v-show="!isMobile"
        mobile-breakpoint="0"
        :headers="headers"
        :disable-sort="true"
        :items="aTable"
        :loading="bLoadingTable"
        loading-text="Cargando datos..."
        :items-per-page="iItemsPerPage"
        :hide-default-footer="true"
        no-data-text="No hay información disponible para mostrar."
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }"
        class="global-table"
      >
        <template slot="progress">
          <v-progress-linear
            color="var(--primary-color-loading-table)"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.sTransactionType`]="{ item }">
          <span
            class="text-transation-type"
            :style="{ color: colorTransationType(item.sTransactionType) }"
            >{{ item.sTransactionType }}</span
          >
        </template>
        <template v-slot:[`item.dAmount`]="{ item }">
          <span
            >$ {{ formatMoneyGlobal(item.dAmount) }} {{ sCurrencyCode }}</span
          >
        </template>
        <template v-slot:[`item.accions`]="{ item }">
          <v-btn
            @click="detailTransation(item)"
            class="global-btn-table-detail"
            icon
          >
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <footer-table-component-global
            :numPages="numPages"
            :totalItems="totalItems"
            @changeTable="getEmitTransation"
          />
        </template>
      </v-data-table>
      <table-mobile-transation-component
        v-show="isMobile"
        @changeTable="getEmitTransation"
        @openDetailItem="detailTransation"
        :aTable="aTable"
        :sCurrencyCode="sCurrencyCode"
        :headers="headers"
        :numPages="numPages"
        :totalItems="totalItems"
      />
      <div class="content-btns-actions-accounts" v-if="bAdminAccount">
        <div class="content-btn-excel">
          <v-btn
            @click="setDialogGenerateReport()"
            class="btn-add-excel global-btn-primary"
          >
            Exportar información
          </v-btn>
        </div>
      </div>
      <dialog-account-generate-report
        @setDialogGenerateReport="setDialogGenerateReport"
        :bDialogGenerateReport="bDialogGenerateReport"
      />
      <dialog-add-transation-component
        :bDialogAddTransation="bDialogAddTransation"
        @setDialogAddTransation="setDialogAddTransation"
        :sCurrencyCode="sCurrencyCode"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    iTab: Number,
  },
  data() {
    return {
      bLoadingTable: true,
      bEmptyTable: false,
      screenHeight: 0,
      bSkeleton: true,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      sTextEmpty: "",

      headers: [
        {
          text: "Folio",
          sortable: false,
          value: "sFolio",
          class: "global-header-table global-header-name",
          cellClass: "global-body-table global-body-name ",
        },
        {
          text: "Tipo de transacción",
          sortable: false,
          value: "sTransactionType",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Beneficiario",
          sortable: false,
          value: "sBeneficiaryName",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Cantidad asociada",
          sortable: false,
          value: "dAmount",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Fecha de transacción",
          sortable: false,
          value: "tTransactionDate",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Tipo de registro",
          value: "sRegistrationType",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aTable: [],
      isMobile: false,
      sSearch: "",
      tStart: "",
      tEnd:"",
      sTransactionType: "",
      sRegistrationType: "",
      bDialogAddTransation: false,
      sCurrencyCode: "",
      bDialogGenerateReport: false,
    };
  },
  beforeMount() {
    this.$store.commit("setsNameDetail", "");
    this.$store
      .dispatch("getPermissionsByUserGlobal")
      .then((resp) => {
        this.setTextEmpty();
        this.getTransation();
      })
      .catch((err) => {
        this.Error(err);
      });
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setDialogGenerateReport() {
      this.bDialogGenerateReport = !this.bDialogGenerateReport;
    },
    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    setRangeDate(oRangeDate) {
      this.tStart = oRangeDate.tStart;
      this.tEnd = oRangeDate.tEnd;
      this.getTransation()
    },
    setTransactionType(sTransactionType) {
      this.sTransactionType = sTransactionType;
    },
    setRegistrationType(sRegistrationType) {
      this.sRegistrationType = sRegistrationType;
    },
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    setDialogAddTransation() {
      this.bDialogAddTransation = !this.bDialogAddTransation;
    },
    getEmitTransation(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getTransation();
    },
    getTransation() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(
            `${URI}/api/sp/v1/accounts/${this.$route.params.id}/transactions`,
            {
              params: {
                iPageNumber: this.iCurrentPage,
                iItemsPerPage: this.iItemsPerPage,
                sSearch: this.sSearch,
                tStart: this.tStart,
                tEnd: this.tEnd,
                sTransactionType: this.sTransactionType,
                sRegistrationType: this.sRegistrationType,
              },
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            }
          )
            .then((response) => {
              this.$store.commit(
                "setsNameDetail",
                response.data.results.oAccount.sAccountName
              );

              this.setAccountBalanc(response.data.results.oAccount.dBalance);
              this.sCurrencyCode =
                response.data.results.oAccount.oCurrency.sCurrencyCode;
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;

              this.aTable = response.data.results.oAccountTransactionsList;
              // .map((e) => {
              //   return {
              //     ...e,
              //     sNameInstitution: e.oFinancialInstitution.sName,
              //     sAccountTypeName: e.oAccountType.sName,
              //     sCurrency: this.formatCurrency(e.oCurrency.sCurrencyId),
              //   };
              // });
              this.bLoadingTable = false;
              this.bSkeleton = false;
              this.bEmptyTable = this.aTable.length < 1;
              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    detailTransation(item) {
      this.$router
        .push(
          `/home/account-detail/${this.$route.params.id}/transation/${item.sAccountTransactionid}`
        )
        .catch(() => {});
    },
    colorTransationType(sTransactionType) {
      if (sTransactionType === "Entrada") {
        return "#00A85B";
      } else {
        return "#E85353";
      }
    },
    nameTransationType(sTransactionType) {
      if (sTransactionType === "Entrada") {
        return "Entrada";
      } else {
        return "Salida";
      }
    },
    setAccountBalanc(dAccountBalance) {
      this.$emit("setAccountBalanc", dAccountBalance);
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen transacciones registradas.</p>";
      this.bAdminAccount
        ? (sTextemptyTemp +=
            "<p>Comienza <span class='text-information-link'>registrando una.</span> </p>")
        : "";
      this.sTextEmpty = sTextemptyTemp;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getTransation();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    refreshTable: function () {
      this.getTransation();
    },
    sSearch() {
      this.getTransation();
    },
    sTransactionType() {
      this.getTransation();
    },
    sRegistrationType() {
      this.getTransation();
    },
    iTab() {
      if (this.iTab === 1) {
        this.$store
          .dispatch("getPermissionsByUserGlobal")
          .then((resp) => {
            this.getTransation();
          })
          .catch((err) => {
            this.Error(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.content-transation {
  display: block;
  width: 100%;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.content-information {
  align-items: center;
  display: flex;
}

.p-title {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
}

.p-text {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-Regular";
  font-size: 14px;
}

.text-color {
  /* -webkit-text-stroke: .5px black; */
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  width: 50%;
  border: solid var(--primary-color-text) 1px !important;
  /* margin-left: 10px; */
  font-family: "pop-Regular" !important;
  font-size: 14px !important;
}

.content-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  height: 100px;
  width: 100px;
}

.content-img img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-img img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.content-btn {
  width: 170px;
  align-self: center;
}

.text-transation-type {
  font-family: "pop-Bold";
}

.content-btns-actions-accounts {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information {
    display: block;
    width: 100%;
  }

  .centered-image {
    display: flex;
    justify-content: center !important;
    text-align-last: center !important;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-information {
    margin-bottom: 10px;
  }

  .p-text-title {
    text-align: center;
  }

  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
    margin-left: 0px;
  }

  .content-color {
    text-align: -webkit-center;
  }

  .content-btn {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
