var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bSkeleton)?_c('skeleton-transation-component'):_c('div',{staticClass:"content-transation"},[_c('div',{staticClass:"global-content-table"},[_c('searchbar-transation-component',{on:{"setSearch":_vm.setSearch,"setRangeDate":_vm.setRangeDate,"setTransactionType":_vm.setTransactionType,"setRegistrationType":_vm.setRegistrationType,"setDialogAddTransation":_vm.setDialogAddTransation}}),(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"iHeight":_vm.screenHeight,"sTextEmpty":_vm.sTextEmpty,"bAdminPermissions":_vm.bAdminAccount,"setDialog":"setDialogAddTransation"},on:{"setDialogAdd":_vm.setDialogAddTransation}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }},scopedSlots:_vm._u([{key:"item.sTransactionType",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-transation-type",style:({ color: _vm.colorTransationType(item.sTransactionType) })},[_vm._v(_vm._s(item.sTransactionType))])]}},{key:"item.dAmount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v("$ "+_vm._s(_vm.formatMoneyGlobal(item.dAmount))+" "+_vm._s(_vm.sCurrencyCode))])]}},{key:"item.accions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"icon":""},on:{"click":function($event){return _vm.detailTransation(item)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitTransation}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-transation-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"sCurrencyCode":_vm.sCurrencyCode,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitTransation,"openDetailItem":_vm.detailTransation}}),(_vm.bAdminAccount)?_c('div',{staticClass:"content-btns-actions-accounts"},[_c('div',{staticClass:"content-btn-excel"},[_c('v-btn',{staticClass:"btn-add-excel global-btn-primary",on:{"click":function($event){return _vm.setDialogGenerateReport()}}},[_vm._v(" Exportar información ")])],1)]):_vm._e(),_c('dialog-account-generate-report',{attrs:{"bDialogGenerateReport":_vm.bDialogGenerateReport},on:{"setDialogGenerateReport":_vm.setDialogGenerateReport}}),_c('dialog-add-transation-component',{attrs:{"bDialogAddTransation":_vm.bDialogAddTransation,"sCurrencyCode":_vm.sCurrencyCode},on:{"setDialogAddTransation":_vm.setDialogAddTransation}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }